import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { END } from 'redux-saga';
import { pdpHelper } from '../../utils';
import styles from './index.module.scss';
import { HeaderActions, HeaderThemes, SagaStore, useAppDispatch, wrapper } from '../../redux';
import { NavigationsActions } from '@dxp/content';
import { useTranslation } from 'next-i18next';
import i18nConfig from '../../next-i18next.config';
import React, { useEffect } from 'react';
import PageHead from '../../components/page-head/page-head';
import PopularSearches from '../../components/popular-searches/popular-searches';

export const Error404Page = () => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(HeaderActions.setLayout(HeaderThemes.ON_DARK));
    return () => {
      dispatch(HeaderActions.setLayout(HeaderThemes.ON_LIGHT));
    };
  }, []);

  return (
    <>
      <PageHead
        title={t('pageNotFound.meta.title')}
        description={t('pageNotFound.meta.description')}
        ogTitle={t('pageNotFound.meta.og.title')}
        ogDescription={t('pageNotFound.meta.og.description')}
        ogImage={t('pageNotFound.meta.og.image')}
        ogImageAlt={t('pageNotFound.meta.og.imageAlt')}
        twitterTitle={t('pageNotFound.meta.twitter.title')}
        twitterDescription={t('pageNotFound.meta.twitter.description')}
        twitterImage={t('pageNotFound.meta.twitter.image')}
        twitterImageAlt={t('pageNotFound.meta.twitter.imageAlt')}
        noIndex={true}
      />
      <div className={styles['container']}>
        <div className={styles['inner']}>
          <h1 className={styles['title']}>{t('pageNotFound.headline')}</h1>

          <PopularSearches layout={'grey'} className={styles['popular-searches']} />
        </div>
      </div>
    </>
  );
};

export default Error404Page;

export const getStaticProps: GetStaticProps = wrapper.getStaticProps((store) => async ({ locale, params }) => {
  locale = locale || (i18nConfig.fallbackLng as string);

  await store.dispatch(NavigationsActions.fetchAll(locale || (i18nConfig.fallbackLng as string)));

  store.dispatch(END);
  await (store as SagaStore).sagaTask?.toPromise();

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], i18nConfig)),
      locale,
    },
    revalidate: pdpHelper.getRevalidation(),
  };
});
