import styles from './popular-searches.module.scss';
import { Colors, Pill, StandardButton } from '@dxp/veneer';
import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { useAppDispatch } from '../../redux';
import Link from 'next/link';
import { PageConfigContext, PlatformWebappStaticRoutes, SearchActions } from '@dxp/core';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import i18nConfig from '../../next-i18next.config';
import Search from '../search/search';

export interface PopularSearchesProps {
  layout: 'grey' | 'white';
}

export function PopularSearches({ layout, className }: PopularSearchesProps & { className?: string }) {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const router = useRouter();
  const pageConfig = useContext(PageConfigContext);

  const search = (searchTerm: string) => {
    dispatch(
      SearchActions.search({
        requestState: { searchTerm },
        locale: router.locale || (i18nConfig.fallbackLng as string),
        addResults: false,
      })
    );
    if (router.pathname !== PlatformWebappStaticRoutes.general.search) {
      router.push({ pathname: PlatformWebappStaticRoutes.general.search }, PlatformWebappStaticRoutes.general.search, {
        locale: router.locale,
      });
    }
  };

  return (
    <div className={classNames(styles['container'], styles['container--' + layout], className)}>
      <div className={styles['cta']}>
        <Search
          theme={layout === 'grey' ? Colors.white : Colors.lightGrey}
          bigSearchbar={true}
          className={styles['cta__search']}
        />

        <Link href={PlatformWebappStaticRoutes.general.home}>
          <StandardButton label={t('pageNotFound.homepage')} variant={Colors.feelgoodMint} />
        </Link>
      </div>
      {pageConfig?.popularSearches && pageConfig?.popularSearches.length > 0 && (
        <div className={styles['popular-searches']}>
          <div className={styles['popular-searches__title']}>{t('pageNotFound.popularSearches')}</div>
          <div className={styles['popular-searches__tags']}>
            {pageConfig?.popularSearches.map((term) => (
              <Pill label={term} key={term} size={'standard'} onClick={() => search(term)} interactive={true} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PopularSearches;
